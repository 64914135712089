// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconUsers, IconClipboardText, } from '@tabler/icons';

// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconUsers,
  IconClipboardText
};


const accounts = {
  id: 'accounts',
  title: 'Accounts',
  type: 'group',
  children: [


    {
      id: 'customers',
      title: 'Customers',
      type: 'item',
      url: '/accounts/customers',
      icon: icons.IconUsers,
      breadcrumbs: false
    },
    {
      id: 'quotes',
      title: 'Quotes',
      type: 'item',
      url: '/accounts/quotes',
      icon: icons.IconClipboardText,
      breadcrumbs: false
    }
    ,

    {
      id: 'quotes-payment',
      title: 'Quote Payment',
      type: 'item',
      url: '/accounts/quote-payment',
      icon: icons.IconClipboardText,
      breadcrumbs: false
    },
    {
      id: 'invoices',
      title: 'Invoices',
      type: 'item',
      url: '/accounts/invoices',
      icon: icons.IconClipboardText,
      breadcrumbs: false
    },
    {
      id: 'invoices-payment',
      title: 'Invoice Payment',
      type: 'item',
      url: '/accounts/invoice-payment',
      icon: icons.IconClipboardText,
      breadcrumbs: false
    },
    {
      id: 'purchase',
      title: 'Purchase',
      type: 'item',
      url: '/accounts/purchase',
      icon: icons.IconClipboardText,
      breadcrumbs: false
    },
  ]
};

export default accounts;
