import dashboard from './dashboard';

import inventory from './inventory';
import accounting from './accounts';
import reports from './reports';
import settings from './settings';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, accounting, inventory, reports, settings]
};

export default menuItems;
