import { ADD_NEW_PRODUCT, DELETE_PRODUCT, END_LOADING, FETCH_PRODUCT, FETCH_PRODUCTS_BY_USER, START_LOADING, UPDATE_PRODUCT } from "../actions/constants";


export const products = (state = { isLoading: true, products: [] }, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };

    case FETCH_PRODUCTS_BY_USER:
      return { ...state, products: action.payload };

    case FETCH_PRODUCT:
      return { ...state, product: action.payload.client };
    case ADD_NEW_PRODUCT:
      return { ...state, products: [...state.products, action.payload] };
    case UPDATE_PRODUCT:
      return { ...state, products: state.products.map((product) => (product._id === action.payload._id ? action.payload : product)) };
    case DELETE_PRODUCT:
      return { ...state, products: state.products.filter((product) => product._id !== action.payload) };
    default:
      return state;
  }
};
