import { combineReducers } from 'redux';

import { products } from '../reducers/products'
import { clients } from '../reducers/clients'
import { invoices } from '../reducers/invoices'
import { profiles } from '../reducers/profiles'

// reducer import
import customizationReducer from './customizationReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  products,
  clients,
  invoices,  
  profiles,
});

export default reducer;
