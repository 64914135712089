import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { QuotePrint } from 'views/accounting/invoices/QuotePrint';

const ComingSoon = Loadable(lazy(() => import('views/fallback/coming-soon/ComingSoon')));


// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

//inventory
const Products = Loadable(lazy(() => import('views/inventory/products/Products')));
const Customers = Loadable(lazy(() => import('views/accounting/customers/Customers')));

const Quotes = Loadable(lazy(() => import('views/accounting/invoices/Quotes')));
const Quote = Loadable(lazy(() => import('views/accounting/invoices/Quote')));

// const QuotePrint = Loadable(lazy(() => import('views/accounting/invoices/QuotePrint')));






// utilities routing
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    // {
    //   path: '/',
    //   element: <DashboardDefault />
    // },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'inventory',
      children: [
        {
          path: 'products',
          element: <Products />
        }
      ]
    },
    {
      path: 'accounts',
      children: [
        {
          path: 'quotes',
          element: <Quotes />
        }
      ]
    },
    {
      path: 'accounts',
      children: [
        {
          path: 'customers',
          element: <Customers />
        }
      ]
    },
    {
      path: 'accounts',
      children: [
        {
          path: 'quote',

          element: <Quote />
        }
      ]
    },
    {
      path: 'accounts',
      children: [
        {
          path: 'quote/:id',

          element: <Quote />
        }
      ]
    },
    {
      path: 'accounts',
      children: [
        {
          path: 'quote-print/:id',
          element: <QuotePrint />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-color',
          element: <UtilsColor />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-shadow',
          element: <UtilsShadow />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'tabler-icons',
          element: <UtilsTablerIcons />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'material-icons',
          element: <UtilsMaterialIcons />
        }
      ]
    },
    {
      path: '*',
      children: [
        {
          path: '*',
          element: <ComingSoon />
        }
      ]
    },


  ]
};

export default MainRoutes;
