// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconUsers, IconClipboardText, } from '@tabler/icons';

// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconUsers,
  IconClipboardText
};


const reports = {
  id: 'reports',
  title: 'Reports',
  type: 'group',
  children: [

    {
      id: 'customers-report',
      title: 'Customers',
      type: 'item',
      url: '/reports/customers',
      icon: icons.IconClipboardText,
      breadcrumbs: false
    },
    {
      id: 'products-report',
      title: 'Products',
      type: 'item',
      url: '/reports/products',
      icon: icons.IconClipboardText,
      breadcrumbs: false
    },
    {
      id: 'customer-ledger',
      title: 'Customer Ledger',
      type: 'item',
      url: '/reports/customer-ledger',
      icon: icons.IconClipboardText,
      breadcrumbs: false
    },
  ]
};

export default reports;
