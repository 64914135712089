import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { useEffect } from "react";
import { useState } from "react";

const CustomerBrandingSection = () => {
  const theme = useTheme();
  const [user, setUser] = useState();
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [localStorage])
  if (!user) {
    return <></>
  }

  return (
    <>

      <Box  >

        <Typography p={2} sx={{
          transition: 'all .2s ease-in-out',
          //background: theme.palette.secondary.light,
          color: theme.palette.secondary.dark,

        }}
          variant='h3'>{user.name}</Typography>

      </Box>
    </>
  );
};

export default CustomerBrandingSection;
