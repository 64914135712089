import * as api from '../api/index'

import { FETCH_PRODUCTS_BY_USER, START_LOADING, END_LOADING, DELETE_PRODUCT, FETCH_PRODUCT, ADD_NEW_PRODUCT } from './constants';

export const getProduct = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING });
  const { data } = await api.fetchProduct(id);
  dispatch({ type: FETCH_PRODUCT, payload: { client: data } });
};



export const getProductsByUser = (searchQuery) => async (dispatch) => {
  dispatch({ type: START_LOADING })
  const data = await api.fetchProductsByUser(searchQuery)
  dispatch({ type: FETCH_PRODUCTS_BY_USER, payload: data });
  dispatch({ type: END_LOADING })
}


export const createProduct = (product) => async (dispatch) => {
  dispatch({ type: START_LOADING })
  const { insertedId } = await api.addProduct(product);
  const data = { ...product, _id: insertedId };
  dispatch({ type: ADD_NEW_PRODUCT, payload: data });
  dispatch({ type: END_LOADING })
  return insertedId;
}
export const updateProduct = (id, product) => async (dispatch) => {
  dispatch({ type: START_LOADING })
  await api.updateProduct(id, product);
  window.location = "/inventory/products"
  dispatch({ type: END_LOADING })
}

export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
    await api.deleteProduct(id)
    dispatch({ type: DELETE_PRODUCT, payload: id })
    dispatch({ type: END_LOADING })
  } catch (error) {
    console.log(error)
  }
}
