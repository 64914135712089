// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconPackages } from '@tabler/icons';

// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconPackages
};


const inventory = {
  id: 'inventory',
  title: 'Inventory',
  type: 'group',
  children: [
    {
      id: 'products',
      title: 'Item Master',
      type: 'item',
      url: '/inventory/products',
      icon: icons.IconPackages,
      breadcrumbs: false
    },
    {
      id: 'stock',
      title: 'Stock',
      type: 'item',
      url: '/inventory/products',
      icon: icons.IconPackages,
      breadcrumbs: false
    }
  ]
};

export default inventory;
