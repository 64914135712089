import { applyMiddleware, createStore } from 'redux';
import reducer from './reducer';

//import reducers from '../reducers';


import { composeWithDevTools } from 'redux-devtools-extension';
import { thunk } from 'redux-thunk'


// ==============================|| REDUX - MAIN STORE ||============================== //

// const store = createStore(reducer);

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export { store };

