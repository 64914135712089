/* eslint-disable */
import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// ==============================|| APP ||============================== //
import { ConfirmProvider } from "material-ui-confirm";
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customization = useSelector((state) => state.customization);

  const handleLogout = async () => {
    const user = localStorage.getItem('profile');
    if (!user) {
      navigate("/login");
    }
  };
  useEffect(() => {
    handleLogout();
  }, [location])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <ConfirmProvider>
          <CssBaseline />
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
        </ConfirmProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
