// material-ui
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import Loader from './Loader';

// styles
const LoaderWrapper = styled('div')({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});



// ==============================|| LOADER ||============================== //
const LoaderRound = () => (
  <>
    <Loader />
    <LoaderWrapper>
      <CircularProgress
        color="primary"
        variant="indeterminate"
      />
    </LoaderWrapper>
  </>

);

export default LoaderRound;
