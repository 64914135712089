import * as api from '../api/index';

import { DELETE, GET_INVOICE, FETCH_INVOICE_BY_USER, START_LOADING, END_LOADING } from './constants'

export const getInvoicesByUser = (searchQuery) => async (dispatch) => {
    dispatch({ type: START_LOADING })
    const data = await api.fetchInvoicesByUser(searchQuery)
    dispatch({ type: FETCH_INVOICE_BY_USER, payload: data });
    dispatch({ type: END_LOADING })
}


export const getInvoice = (id) => async (dispatch) => {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchInvoice(id)
    const businessDetails = await api.fetchProfile();
    const invoiceData = { ...data, businessDetails }
    dispatch({ type: GET_INVOICE, payload: invoiceData })
    dispatch({ type: END_LOADING });
}

export const createInvoice = (invoice) => async (dispatch) => {
    dispatch({ type: START_LOADING })
    const { insertedId } = await api.addInvoice(invoice)
    dispatch({ type: END_LOADING })
    return insertedId;
}


export const updateInvoice = (id, invoice) => async (dispatch) => {
    dispatch({ type: START_LOADING })
    await api.updateInvoice(id, invoice)
    dispatch({ type: END_LOADING })
}

export const deleteInvoice = (id) => async (dispatch) => {
    dispatch({ type: START_LOADING });
    await api.deleteInvoice(id);
    dispatch({ type: END_LOADING });
    dispatch({ type: DELETE })

}